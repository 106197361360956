import React from "react"
import { FacebookProvider, Page } from "react-facebook"
import styled from "styled-components"
import useWindowSize from "../../hooks/useWindowSize"
import {
  PageBuilderActiviteitFacebookFragment,
  PageBuilderErvaringFacebookFragment,
  PageBuilderFacebookFragment,
  PageBuilderNieuwsFacebookFragment,
  PageBuilderVacatureFacebookFragment,
} from "../../__generated__/graphql-gatsby"

interface IProps {
  block:
    | PageBuilderFacebookFragment
    | PageBuilderErvaringFacebookFragment
    | PageBuilderActiviteitFacebookFragment
    | PageBuilderNieuwsFacebookFragment
    | PageBuilderVacatureFacebookFragment
}

const Wrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0`};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > div:first-child {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    box-shadow: ${({ theme }) => theme.shadows[4]};
    overflow: hidden;
  }
`

const Facebook: React.FC<IProps> = ({ block: { tabs: tabsProp } }) => {
  if (!tabsProp) return null

  const { width: windowWidth, height: windowHeight } = useWindowSize()

  if (!windowWidth || !windowHeight) return null

  let width = 180
  let height = 720

  if (windowHeight - 142 < height) {
    height = windowHeight - 166
  }

  if (windowWidth > 500) {
    width = 500
  } else if (windowWidth < 180) {
    width = 180
  } else {
    // subtract 16 to emulate page padding on the right
    width = windowWidth - 16
  }

  const fbUrl = "https://www.facebook.com/toonhermanshuisamersfoort/"
  const tabs = tabsProp.length === 0 ? "timeline" : tabsProp.join(",")

  return (
    <FacebookProvider
      appId={process.env.GATSBY_FB_APP_ID}
      language="nl_NL"
      version="v12.0"
    >
      <Wrapper>
        <Page
          href={fbUrl}
          tabs={tabs}
          height={height}
          width={width}
          showFacepile
          adaptContainerWidth={false}
          smallHeader={false}
          hideCover={false}
        />
      </Wrapper>
    </FacebookProvider>
  )
}

export default Facebook
